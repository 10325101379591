import React from "react"
import tw from "twin.macro"
import Logo from "assets/svgs/logo-primary.svg"

function NotFoundPage() {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <Logo css={tw`w-auto h-6`} />
      <h3 css={tw`text-xl text-gray-600 font-semibold mt-4`}>
        Oops! Page Not Found
      </h3>
    </div>
  )
}

export default NotFoundPage
